// /src/components/CarrinhoCart.js

import React, { useState, useEffect, useCallback } from "react";
import { FaShare, FaTimes } from "react-icons/fa";

const retornaRData = (dateString) => {
  const inputFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
  const match = dateString.match(inputFormat);

  if (match) {
    const year = match[1];
    const month = match[2] - 1;
    const day = match[3];
    const hours = match[4];
    const minutes = match[5];
    const seconds = match[6];

    const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

    const formatter = new Intl.DateTimeFormat("pt-PT", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    return formatter.format(date);
  } else {
    return dateString;
  }
};

const formatCurrency = (value, locale = "pt-AO", currency = "AOA") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};

const CarrinhoCart = ({ product, remover, actualizar }) => {
  const [secondsLeft, setSecondsLeft] = useState(50);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft((seconds) => (seconds > 0 ? seconds - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleRemove = useCallback(() => {
    remover(product.id);
  }, [remover, product.id]);

  const handleUpdate = useCallback((quantity) => {
    actualizar(product.id, quantity);
  }, [actualizar, product.id]);

  return (
    <div className="flex flex-col md:flex-row justify-between items-center mt-6 relative bg-gray-100 rounded-lg p-4 shadow-md">
      <button onClick={handleRemove} className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-700">
        <FaTimes size={16} />
      </button>
      <div className="flex items-center w-full md:w-auto mb-4 md:mb-0">
        <img
          src={product.foto}
          alt={product.produto}
          className="rounded mr-4 h-40 w-40 object-cover"
        />
        <div className="flex flex-col text-sm text-left">
          <h2 className="text-lg uppercase font-bold">{product.produto}</h2>
          <p className="text-gray-700 uppercase">
            Preço unitário {formatCurrency(product.preco / product.quantidade)}
          </p>
          <p className="text-gray-700 uppercase">
            Desconto {formatCurrency(product.desconto)}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-end text-right w-full md:w-auto">
        <div className="flex items-center space-x-2">
          <button onClick={() => handleUpdate(product.quantidade - 1)} className="bg-gray-200 text-gray-600 p-2 rounded">-</button>
          <span className="text-sm">{product.quantidade}</span>
          <button onClick={() => handleUpdate(product.quantidade + 1)} className="bg-gray-200 text-gray-600 p-2 rounded">+</button>
        </div>
        <p className="text-lg font-semibold mt-4">
          {formatCurrency(product.preco)}
        </p>
      </div>
    </div>
  );
};

export default CarrinhoCart;
