// src/stores/AppStore.js

import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { ApiActionTypes } from '../actions/ApiActions';


let _products = [];
let _productsBD = [];
let _productSubscrito = [];
let _recomendados = [];
let _produto ;
let _cart = [];
let _grupos = [];
let _categorias = [];
let _socias = [];
let _servicos = [];
let _transportadoras = [];
let _sociasDetalhe;
let _promocoes = [];
let _promocao;
let _despensa = [];
let _localidades = [];
let _respostaBai;
let _respostaUnitelMoney ;
let _isLoading = false;
let _isSaving = false;
let _isAdding = false;

function setProduto(produto) {
    _produto = produto;
}
function setProdutosBD(produto) {
    _productsBD = produto;
}
function setProdutosSubscritos(produto) {
    _productSubscrito = produto;
}
function setTransportadoras(transportadora) {
    _transportadoras = transportadora;
}
function setGrupos(grupo) {
    _grupos = grupo;
}
function setServicos(grupo) {
    _servicos = grupo;
}
function setPromocao(promocao) {
    _promocao = promocao;
}
function setProducts(products) {
    _products = products;
}
function setRecomendados(products) {
    _recomendados = products;
}
function setDetalheSocia(sociasDetalhe) {
    _sociasDetalhe = sociasDetalhe;
}
function setBai(respostaBai) {
    _respostaBai = respostaBai;
}
function setUnitel(respostaUnitelMoney) {
    _respostaUnitelMoney = respostaUnitelMoney;
}
function setLocalidade(localidades) {
    _localidades = localidades;
}

function setProductsCategory(products) {
    _products = products;
}

function setPromo(promocoes) {
    _promocoes = promocoes;
}

function setCategoria(categorias) {
    _categorias = categorias;
}

function setSocias(socias) {
    _socias = socias;
}

function setLoading(loading) {
    _isLoading = loading;
}

function updateCart(cart) {
    _cart = cart;
}

class AppStore extends EventEmitter {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            _isSaving: false,
            _isAdding: false,
            // other states...
        };

        AppDispatcher.register((action) => {

            switch (action.type) {
                case ApiActionTypes.START_LOADING_BD:
                    setLoading(true);
                    this.emit('acarregarBD');
                    break;
                case ApiActionTypes.SUCESS_LOADING_BD:
                    setProdutosBD(action.products);
                    this.emit('BdSucesso');
                    break;
                case ApiActionTypes.START_LOADING_SUBSCRICAO:
                    setLoading(true);
                    this.emit('acarregarSubscricao');
                    break;
                case ApiActionTypes.SUCESS_LOADING_SUBSCRICAO:
                    setProdutosSubscritos(action.products);
                    this.emit('subscricaoSucesso');
                    break;
                  case ApiActionTypes.RECOMENDADOS_START:
                    setLoading(true);
                    this.emit('acarregarRecomendados');
                    break;
                case ApiActionTypes.RECOMENDADOS_SUCCESS:
                       setLoading(false);
                    setRecomendados(action.products);
                    this.emit('acarregarSucesso');
                    break;
                 case ApiActionTypes.RECOMENDADOS_ERROR:
                    setLoading(false);
                    this.emit('acarregarErro');
                    break;
                 case ApiActionTypes.SERVICO_START:
                    setLoading(true);
                    this.emit('carregarSERVICO');
                    break;
                case ApiActionTypes.SERVICO_SUCCESS:
                       setLoading(false);
                    setServicos(action.products);
                    this.emit('sucessoSERVICO');
                    break;
                 case ApiActionTypes.SERVICO_ERROR:
                    setLoading(false);
                    this.emit('erroSERVICO');
                    break;
                case ApiActionTypes.START_LOADING_TRANSPORTADORAS:
                    setLoading(true);
                    this.emit('carregarTransportadora');
                    break;
                case ApiActionTypes.SUCESS_LOADING_TRANSPORTADORAS:
                    // Verifique se os dados estão corretos
                    setTransportadoras(action.products);
                    setLoading(false);
                    this.emit('change_transportadoras');
                    break;
                 case ApiActionTypes.LOAD_GRUPO_START:
                    setLoading(true);
                    this.emit('carregando');
                    break;
                case ApiActionTypes.LOAD_GRUPO_SUCCESS:
                    // Verifique se os dados estão corretos
                    setGrupos(action.products);
                    setLoading(false);
                    this.emit('change_grupos');
                    break;
                case ApiActionTypes.LOAD_GRUPO_ERROR:
                    setLoading(false);
                    this.emit('error_grupos');
                    break;
                case ApiActionTypes.START_LOADING:
                    setLoading(true);
                    this.emit('carregando');
                    break;
                case ApiActionTypes.END_LOADING:

                    setLoading(false);
                    this.emit('carregando');
                    break;
                case ApiActionTypes.CATEGORIA_LOAD_DATA_SUCCESS:
                    setCategoria(action.products)

                    // Atualizar _products com a resposta da API
                    this.emit('change_categoria');
                    break;
                case ApiActionTypes.SOCIA_LOAD_DATA_SUCCESS:
                    setSocias(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('change_socia');
                    break;
                 case ApiActionTypes.LOAD_PRODUCTS:
                   
                    setProducts(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('change');
                    break;
                case ApiActionTypes.PROMO_LOAD_DATA_SUCCESS:
                    setPromo(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('change_promo');
                    break;
                // BAI PAGA
                 case ApiActionTypes.BAIPAGA_LOAD_DATA_START:
                    setLoading(true);
                    this.emit('bai_start');
                    break;
                 case ApiActionTypes.BAIPAGA_LOAD_DATA_SUCCESS:
                    setBai(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('bai_sucesso');
                    break;
                case ApiActionTypes.BAIPAGA_LOAD_DATA_ERROR:
                    setBai(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('bai_erro');
                    break;
              
                // UNITEL MONEY
                case ApiActionTypes.UNITEL_LOAD_DATA_START:
                    setLoading(true);
                    this.emit('unitel_start');
                    break;
                 case ApiActionTypes.UNITEL_LOAD_DATA_SUCCESS:
                    setUnitel(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('unitel_sucesso');
                    break;
                case ApiActionTypes.UNITEL_LOAD_DATA_ERROR:
                    setUnitel(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('unitel_erro');
                    break;
                
                // LOCALIDADE
                case ApiActionTypes.LOCALIDADE_LOAD_DATA_START:
                    setLoading(true);
                    this.emit('localidade_start');
                    break;
                 case ApiActionTypes.LOCALIDADE_LOAD_DATA_SUCCESS:
                    setLocalidade(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('localidade_sucesso');
                    break;
                case ApiActionTypes.LOCALIDADE_LOAD_DATA_ERROR:
                    setLocalidade(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('localidade_erro');
                    break;
               
                // CARREGAR DETALHE SOCIA
                case ApiActionTypes.LOAD_DETALHE_SUCCESS:
               
                    setDetalheSocia(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('sociaDetalheSucesso');
                    break;
               
                case ApiActionTypes.LOAD_DETALHE_PRODUTO_SUCCESS:
          
                    setProduto(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('detalheProduto');
                    break;

                case ApiActionTypes.LOAD_DETALHE_PROMO_SUCCESS:
             
                    setPromocao(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('detalhePromo');
                    break;

                case ApiActionTypes.CATEGORIA_PRODUTO_LOAD_DATA_SUCCESS:

                    setProductsCategory(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('change');
                    break;
                case ApiActionTypes.CATEGORIA_PRODUTO_LOAD_DATA_SUCCESS:

                    setProductsCategory(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('change');
                    break;
                case ApiActionTypes.CARRINHO_LOAD_DATA_SUCCESS:
                    
                    updateCart(action.products)
                        // Atualizar _products com a resposta da API
                    this.emit('change_carrinho');
                    break;
                case ApiActionTypes.ADD_TO_CART:
                    
                    updateCart(action.cart);
                    this.emit('produto_adicionado', action.cart);
                    break;
                    // Implementar demais cases
                case ApiActionTypes.ADD_TO_CART_ERROR:
                    // Adicionar produto ao _cart
                    updateCart(action.cart);
                    this.emit('produto_adicionado_erro');
                    break;
                    // Implementar demais cases
                default:
                    // No default action
            }
        });
    }
    getState() {
        return this.state;
    }

    getProducts() {
        return _products;
    }
     getRecomendados() {
        return _recomendados;
     }
     getServicos() {
        return _servicos;
    }
    getGrupos() {
        return _grupos;
    }
    getProductsCategory() {
        return _products;
    }

    getCart() {
        return _cart;
    }

    getIsLoading() {
        return _isLoading;
    }
    getPromo() {
        return _promocoes
    }
    getCategorias() {
        return _categorias
    }
    getSocias() {
        return _socias
    }
     getBai() {
        return _respostaBai
     }
    getLocalidades() {
        return _localidades
    }
     getUnitel() {
        return _respostaUnitelMoney
     }
     getDetalheSocia() {
        return _sociasDetalhe
     }
    getProduto() {
         
        return _produto
     }
     getPromocao() {
        return _promocao
     }
    getTransportadora() {
        return _transportadoras
    }
    getBD() {
        return _productsBD
    }
    getSubscritos() {
        return _productSubscrito
    }
}

export default new AppStore();