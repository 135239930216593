// /src/components/Carrinho.js

import React, { useEffect, useState, useCallback } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { ApiActions } from "../../actions/ApiActions";
import AppStore from "../../stores/AppStore";
import CarrinhoCart from "./CarrinhoCart";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

function PriceDisplay({ price }) {
  return <div>{formatCurrency(price)}</div>;
}

PriceDisplay.propTypes = {
  price: PropTypes.number.isRequired,
};

export default function Carrinho({ onAddToCart }) {
  const [isLoading, setIsLoading] = useState(AppStore.getIsLoading());
  const [products, setProducts] = useState(AppStore.getCart());
  const [paymentMethod, setPaymentMethod] = useState("");
  const [coupon, setCoupon] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [ValidCoupon, setValidCoupon] = useState("");
  const [carrier, setCarrier] = useState("");
  const [carriers, setCarriers] = useState(AppStore.getTransportadora());

  const paymentMethods = [
    { code: "tpa", name: "TPA" },
    { code: "bai", name: "BAI PAGA" },
    { code: "unitel", name: "UNITEL MONEY" },
    { code: "tb", name: "TRANSFERÊNCIA BANCARIA" },
  ];

  useEffect(() => {
    function handleChanges() {
      setProducts(AppStore.getCart());
      handleLoading();
    }
    function handleChangesTransportadora() {
      setCarriers(AppStore.getTransportadora());
    }

    function handleLoading() {
      setIsLoading(AppStore.getIsLoading());
    }

    AppStore.on("carregando", handleLoading);
    AppStore.on("change_carrinho", handleChanges);
    AppStore.on("change_transportadoras", handleChangesTransportadora);

    ApiActions.loadCart();
    ApiActions.loadTransportadoras();
    return () => {
      AppStore.removeListener("change_carrinho", handleChanges);
      AppStore.removeListener("carregando", handleLoading);
    };
  }, []);

  const notificarSucesso = useCallback(() => {
    toast.success("Compra finalizada com sucesso!");
  }, []);

  const notificarErro = useCallback(() => {
    toast.error("Não foi possível finalizar a compra!");
  }, []);

  const handlePaymentMethodChange = useCallback((event) => {
    setPaymentMethod(event.target.value);
  }, []);

  const handleCarrierChange = useCallback((event) => {
    setCarrier(event.target.value);
  }, []);

  const handleAddToCart = useCallback(() => {
    setIsAdding(true);

    const endpoint = getPaymentEndpoint(paymentMethod);

    ApiActions[endpoint](products[0].id, carrier)
      .then(response => {
        if (endpoint === "PagarUnitel") {
          toast.success(response.data.objecto.custBuyGoodRs.buyGoodsRec.resultDesc.toUpperCase());
        } else if (endpoint === "PagarBAI") { 
          const url = response.data.objecto.confirmationUrl;
          window.open(url, '_blank', 'noopener,noreferrer');
        } else { 
          notificarSucesso();
        }

        console.log("Resposta da API:", response);
      })
      .catch((error) => {
        console.log("Resposta da API:", error);
        notificarErro();
      })
      .finally(() => {
        setIsAdding(false);
      });
  }, [products, paymentMethod, carrier, notificarSucesso, notificarErro]);

  const getPaymentEndpoint = (method) => {
    switch (method) {
      case "tpa":
        return "PagarIban";
      case "unitel":
        return "PagarUnitel";
      case "bai":
        return "PagarBAI";
      default:
        return "PagarIban";
    }
  };

  const RemoverCarrinho = useCallback((id) => {
    ApiActions.RemoverProCarrinho(id)
      .then(() => {
        setProducts((prevProducts) =>
          prevProducts.map((cartItem) => ({
            ...cartItem,
            produtos: cartItem.produtos.filter((product) => product.id !== id),
          }))
        );
      })
      .catch((error) => {
        console.error("Error removing from cart:", error);
      });
  }, []);

  const handleIncrementQuantity = useCallback((productId) => {
    setProducts((prevProducts) =>
      prevProducts.map((cartItem) => ({
        ...cartItem,
        produtos: cartItem.produtos.map((product) =>
          product.id === productId
            ? { ...product, quantidade: product.quantidade + 1 }
            : product
        ),
      }))
    );
  }, []);

  const handleDecrementQuantity = useCallback((productId) => {
    setProducts((prevProducts) =>
      prevProducts.map((cartItem) => ({
        ...cartItem,
        produtos: cartItem.produtos.map((product) =>
          product.id === productId
            ? {
                ...product,
                quantidade: Math.max(1, product.quantidade - 1),
              }
            : product
        ),
      }))
    );
  }, []);

  const applyCoupon = useCallback(
    (e) => {
      e.preventDefault();
      ApiActions.addVoucher(coupon)
        .then(() => {
          toast.success("Cupom adicionado!");
          setValidCoupon("Ok");
          setCoupon("");
        })
        .catch((error) => {
          toast.error("Cupom de desconto inválido!");
          notificarErro();
        })
        .finally(() => {
          setIsAdding(false);
        });
    },
    [coupon, notificarErro]
  );

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <BeatLoader />
      </div>
    );
  }

  if (!products || products.length === 0 || !products[0].produtos) {
    return (
      <div className="flex flex-col items-center justify-center h-96">
        <main className="flex flex-col items-center justify-center h-screen">
          <img
            src="https://illustrations.popsy.co/fuchsia/shopping-cart.svg"
            alt="Carrinho vazio"
            className="h-52 select-none object-contain"
          />
          <h1 className="text-2xl text-customPurple font-extrabold tracking-tight lg:text-4xl select-none">
            Carrinho vazio
          </h1>
          <p className="leading-7 mt-6 text-center mb-5">
            Não espere mais, comece agora e leve para casa os melhores produtos
          </p>
        </main>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col md:flex-row justify-center items-start gap-3 pt-24 pb-24">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-2xl">
        {products[0].produtos
          .slice()
          .reverse()
          .map((product) => (
            <CarrinhoCart
              key={product.id}
              product={product}
              remover={RemoverCarrinho}
              actualizar={(id, quantidade) =>
                quantidade > 0
                  ? handleIncrementQuantity(id)
                  : handleDecrementQuantity(id)
              }
            />
          ))}
      </div>
      <form className="bg-white p-4 border rounded w-full max-w-sm">
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl uppercase font-semibold pb-2">
            Dados de Pagamento
          </h2>
          <div>
            <p className="uppercase">
              Subtotal:{" "}
              <span className="font-bold">
                {formatCurrency(products[0].subTotal)}
              </span>
            </p>
            <p className="uppercase">
              Custo de Entrega:{" "}
              <span className="font-bold">
                {formatCurrency(products[0].custoTransporte)}
              </span>
            </p>
            <p className="uppercase">
              Desconto:{" "}
              <span className="font-bold">
                {formatCurrency(products[0].desconto)}
              </span>
            </p>
            <p className="uppercase">
              Cupon de Desconto:{" "}
              <span className="font-bold">
                {formatCurrency(products[0].pontos)}
              </span>
            </p>
          </div>
          <div className="py-4 border-b border-t">
            <div className="flex justify-between uppercase text-end">
              <span>Total</span>
              <span className="font-bold text-lg">
                {formatCurrency(products[0].valoraPagar)}
              </span>
            </div>
          </div>
          <div className="py-4 border-b w-full">
            <h3 className="font-semibold uppercase mb-2">Transportadora</h3>
            <select
              value={carrier}
              onChange={handleCarrierChange}
              className="w-full px-3 py-2 border rounded"
              aria-label="Selecionar Transportadora"
            >
              <option value="" disabled>
               Selecione uma empresa de entrega
              </option>
              {carriers.map((carrier) => (
                <option key={carrier.id} value={carrier.id}>
                  {carrier.prestador}
                </option>
              ))}
            </select>
          </div>
          <div className="py-4 border-b w-full">
            <h3 className="font-semibold uppercase mb-2">Como vai pagar </h3>
            <select
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
              className="w-full px-3 py-2 border rounded"
              aria-label="Selecionar Forma de Pagamento"
            >
              <option value="" disabled>
                Selecione uma forma de pagamento
              </option>
              {paymentMethods.map((method) => (
                <option key={method.code} value={method.code}>
                  {method.name}
                </option>
              ))}
            </select>
          </div>
          {ValidCoupon !== "Ok" && (
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Cupom de desconto"
                className="flex-1 px-2 py-3 outline-none border rounded-md"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                aria-label="Cupom de desconto"
              />
              <button
                className="ml-2 px-4 py-3 bg-customPurple text-white rounded-md"
                onClick={applyCoupon}
                aria-label="Aplicar Cupom"
              >
                Aplicar
              </button>
            </div>
          )}
          <div className="mt-6">
            {isAdding && <BeatLoader />}
            {products[0].confirmado ? (
              <h3 className="font-semibold mb-2 text-center">
                COMPRA CONFIRMADA
              </h3>
            ) : (
              <button
                className="w-full bg-customPurple text-white py-3 rounded"
                onClick={handleAddToCart}
                disabled={isAdding}
                aria-label="Finalizar Compra"
              >
                FINALIZAR COMPRA
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

Carrinho.propTypes = {
  onAddToCart: PropTypes.func.isRequired,
};
