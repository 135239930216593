import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiActions } from "./actions/ApiActions";
import AppStore from "./stores/AppStore";
import { UserProvider } from "./components/UserContext";
import { SelectedItemProvider } from "./components/SelectedItemContext";
import ItemDetailModal from "./components/ItemDetailModal";
import NavigationBar from "./components/HELPERS/NavigationBar";
import Modal from "./components/Modal";
import LoginModal from "./components/LoginModal";
import RegisterModal from "./components/Register";
import Pesquisa from "./components/PRODUTO/Pesquisa";
import ProdutosCategoria from "./components/PRODUTO/ProdutosCategoria";
import TodoProdutos from "./components/PRODUTO/SubscribedProducts";
import Sociadetalhe from "./components/SOCIA/Sociadetalhe";
import ComprarSocia from "./components/SOCIA/SociaExpress";
import Historico from "./components/HISTORICO/Historico";
import Inicio from "./components/START/Inicio";
import FetchAllSocia from "./components/SOCIA/FetchAllSocia";
import ProductDetailPage from "./components/PRODUTO/ProductDetailPage";
import ServiceDetalhe from "./components/PRODUTO/ServiceDetalhe";
import Carrinho from "./components/CARRINHO/Carrinho";
import Roleta from "./components/ROLETA/Roleta";
import RegisterRef from "./components/REGISTER/RegistrationReferral";
import Recomprar from "./components/CARRINHO/Recomprar";
import PromoDetailPage from "./components/PROMOCOES/PromoDetailPage";
import Despensa from "./components/DESPENSA/Despensa";
import Profile from "./components/PROFILE/Profile";
import JoinGroup from "./components/PROFILE/JoinGroup";
import Grupo from "./components/PROFILE/GroupManagement";
import CashBack from "./components/PROFILE/CashBack";
import Novidades from "./components/PRODUTO/Novidades";

import './fonts.css';
import "./App.css";

function App() {
  const [categorias, setCategorias] = useState(AppStore.getCategorias());
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  useEffect(() => {
    function handleChanges() {
      setCategorias(AppStore.getCategorias());
    }

    AppStore.on("change_categoria", handleChanges);
    ApiActions.loadCategorias();

    return () => {
      AppStore.removeListener("change_categoria", handleChanges);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Sócia</title>
      </Helmet>

      <Router>
        <div className="bg-white min-h-screen flex flex-col">
          <header className="w-full bg-white z-50 fixed top-0">
            <div className="container mx-auto py-4">
              <NavigationBar categorias={categorias} />
            </div>
          </header>
          <UserProvider>
            <SelectedItemProvider>
              <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 mt-20">
                <Routes>
                  <Route
                    path="/inicio"
                    element={<Inicio onShowModal={setIsLoginModalOpen} />}
                  />
                  <Route path="/socias" element={<FetchAllSocia />} />
                  <Route path="/carrinho" element={<Carrinho />} />
                  <Route path="/despensa" element={<Despensa />} />
                  <Route path="/express" element={<ComprarSocia />} />
                  <Route path="/historico" element={<Historico />} />
                   <Route path="/todos" element={<TodoProdutos />} />
                  <Route path="/carrinho/recomprar" element={<Recomprar />} />
                  <Route path="/roleta" element={<Roleta />} />
                  <Route path="/mais" element={<CashBack />} />
                  <Route path="/grupos" element={<Grupo />} />
                   <Route path="/novos" element={<Novidades onShowModal={setIsLoginModalOpen} />} />
                  <Route path="/invite" element={<JoinGroup onShowModal={setIsLoginModalOpen} />} />
                  <Route path="/registro/ref/:inviter" element={<RegisterRef />} />
                  <Route path="/" element={<Inicio onShowModal={setIsLoginModalOpen} />} />
                  <Route path="/product/:Id" element={<ProductDetailPage />} />
                  <Route path="/service/:Id" element={<ServiceDetalhe />} />
                  <Route path="/despensa/:Id" element={<ProductDetailPage />} />
                  <Route path="/grupoCompra/:Id" element={<ComprarSocia />} />
                  <Route path="/promo/:Id" element={<PromoDetailPage />} />
                  <Route path="/socia/:Id" element={<Sociadetalhe />} />
                  <Route
                    path="/produtoscategoria"
                    element={<ProdutosCategoria onShowModal={setIsLoginModalOpen} />}
                  />
                  <Route
                    path="/pesquisa/:searchTerm"
                    element={<Pesquisa onShowModal={setIsLoginModalOpen} />}
                  />
                </Routes>
              </main>
              <ToastContainer />
              <ItemDetailModal />
              <Modal
                isOpen={isLoginModalOpen}
                onClose={() => setIsLoginModalOpen(false)}
              >
                <LoginModal />
              </Modal>
              <Modal
                isOpen={isRegisterModalOpen}
                onClose={() => setIsRegisterModalOpen(false)}
              >
                <RegisterModal />
              </Modal>
            </SelectedItemProvider>
            <footer className="bg-yellow-300 text-center p-4 mt-auto">
              <div>
                <p className="text-lg font-semibold">
                  No mínimo <span className="text-xl text-red-600">10%</span> mais barato que o Supermercado
                </p>
              </div>
            </footer>
          </UserProvider>
        </div>
      </Router>
    </>
  );
}

export default App;
