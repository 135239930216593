import React, { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { ApiActions } from "../../actions/ApiActions";
import AppStore from "../../stores/AppStore";
const Sidebar = ({ produto, onClose, onSubscribeConfirm }) => {
  const [frequency, setFrequency] = useState("mensal");
  const [dayOfWeek, setDayOfWeek] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState("");

  const handleSubscribe = () => {
    if (quantity <= 0) {
      setError("Quantidade deve ser maior que zero.");
      return;
    }
    setError("");
    onSubscribeConfirm({ produto, frequency, dayOfWeek, quantity });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-50">
      <div className="bg-white w-80 h-full p-4 overflow-y-auto">
        <h2 className="text-xl uppercase font-semibold mb-4">Subscrever Recorrência</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <label className="block mb-2">Frequência</label>
          <select value={frequency} onChange={(e) => setFrequency(e.target.value)} className="w-full p-2 border rounded">
            <option value="mensal">Mensal</option>
            <option value="quinzenal">Quinzenal</option>
            <option value="semanal">Semanal</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Dia da Semana</label>
          <select value={dayOfWeek} onChange={(e) => setDayOfWeek(parseInt(e.target.value))} className="w-full p-2 border rounded">
            <option value={1}>Segunda</option>
            <option value={2}>Terça</option>
            <option value={3}>Quarta</option>
            <option value={4}>Quinta</option>
            <option value={5}>Sexta</option>
            <option value={6}>Sábado</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Quantidade</label>
          <input type="number" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} min={1} className="w-full p-2 border rounded" />
        </div>
        <div className="flex justify-between">
          <button className="bg-customPurple text-white px-4 py-2 rounded w-1/2 mr-2" onClick={handleSubscribe}>
            Subscrever
          </button>
          <button className="text-gray-500 px-4 py-2 rounded w-1/2 ml-2 opacity-70" onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;