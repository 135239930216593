import React, { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { ApiActions } from "../../actions/ApiActions";
import AppStore from "../../stores/AppStore";
import Sidebar from "./Sidebar";

function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

const SubscribeProduct = ({ produto, onSubscribe }) => {
  const currentPrice = produto.preco / produto.quantidade;

  return (
    <div key={produto.id} className="bg-white shadow rounded flex flex-col h-full">
      <img src={produto.foto} alt={produto.produto.toUpperCase()} className="w-full h-36 object-contain" />
      <div className="p-4 flex flex-col items-center justify-center flex-1">
        <h2 className="text-lg font-semibold mb-2 text-center">{produto.produto.toUpperCase()}</h2>
        <div className="flex items-center">
         
        </div>
        <div className="mt-auto w-full">
          <button className="bg-customPurple text-white px-4 py-2 rounded mt-4 w-full" onClick={() => onSubscribe(produto)}>
            Subscrever
          </button>
        </div>
      </div>
    </div>
  );
};

const AllProducts = ({ onAddToCart, onSubscribeConfirm, onBack }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState(AppStore.getBD());
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    function handleChanges() {
      const products = AppStore.getBD();
      setProducts(products);
      setFilteredProducts(products);
    }
    function handleLoading() {
      setIsLoading(false);
    }

    AppStore.on("acarregarBD", handleLoading);
    AppStore.on("BdSucesso", handleChanges);

    ApiActions.loadBD();
    return () => {
      AppStore.removeListener("BdSucesso", handleChanges);
      AppStore.removeListener("acarregarBD", handleLoading);
    };
  }, []);

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  useEffect(() => {
    const normalizedSearchTerm = normalizeText(searchTerm);
    setFilteredProducts(
      products.filter((produto) =>
        normalizeText(produto.produto).includes(normalizedSearchTerm)
      )
    );
  }, [searchTerm, products]);

  const handleSubscribeClick = (produto) => {
    setSelectedProduct(produto);
    setIsSidebarOpen(true);
  };

  const handleSubscribeConfirm = ({ produto, frequency, dayOfWeek, quantity }) => {
    onSubscribeConfirm({ produto, frequency, dayOfWeek, quantity });
    setIsSidebarOpen(false);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <BeatLoader />
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="p-4">
        <button onClick={onBack} className="bg-gray-300 px-4 py-2 rounded mb-4">Voltar</button>
        <input
          type="text"
          placeholder="Pesquisar produtos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded mb-4"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 py-8">
        {filteredProducts.map((produto, index) => (
          <SubscribeProduct key={index} addCarrinho={onAddToCart} produto={produto} onSubscribe={handleSubscribeClick} />
        ))}
      </div>
      {isSidebarOpen && (
        <Sidebar
          produto={selectedProduct}
          onClose={() => setIsSidebarOpen(false)}
          onSubscribeConfirm={handleSubscribeConfirm}
        />
      )}
    </div>
  );
};

export default AllProducts;
