import React, { useState, useEffect } from "react";
import AllProducts from "./AllProducts";
import Sidebar from "./Sidebar";
import AppStore from "../../stores/AppStore";
import { ApiActions } from "../../actions/ApiActions";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css"; // Import necessary styles for the context menu
import { toast } from "react-toastify";
function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

const SubscribedProductCard = ({ product, onEdit, onDelete }) => {
  return (
    <div key={product.productName} className="bg-white shadow rounded flex flex-col h-full relative">
      <img src={product.foto} alt={product.produto.toUpperCase()} className="w-full h-36 object-contain" />
      <div className="p-4 flex flex-col items-center justify-center flex-1">
        <h2 className="text-lg font-semibold mb-2 text-center">{product.produto.toUpperCase()}</h2>
        <div className="flex items-center">
          <p className="text-lg uppercase text-green-600">
            {product.quantidade}   {product.divididoPor} 
          </p>
        </div>
        <p className="text-sm uppercase text-green-500 mb-2">
          Recorrência: {product.frequencia}
        </p>
        <p className="text-sm uppercase text-center text-green-500 mb-2">
          Proxima Entrega: {product.proximaEntrega}
        </p>
        <div className="mt-auto w-full flex justify-between items-center">
          <Menu menuButton={<MenuButton>...</MenuButton>}>
            {/* <MenuItem onClick={() => onEdit(product)}>Editar</MenuItem> */}
            <MenuItem onClick={() => onDelete(product.id)}>Eliminar Subscrição</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

const SubscribedProducts = () => {
  const [subscribedProducts, setSubscribedProducts] = useState(AppStore.getSubscritos());
  const [isAllProductsVisible, setIsAllProductsVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const handleChanges = () => {
      setSubscribedProducts(AppStore.getSubscritos());
    };

    AppStore.on("subscricaoSucesso", handleChanges);
    ApiActions.loadMinhasSubescricao();

    return () => {
      AppStore.removeListener("subscricaoSucesso", handleChanges);
    };
  }, []);

  const handleNewSubscription = () => {
    setIsAllProductsVisible(true);
  };

  const handleEditSubscription = (product) => {
    setSelectedProduct(product);
    setIsSidebarOpen(true);
  };

    const handleDeleteSubscription = (productId) => {
      ApiActions.removerSubescricao(productId)
      .then(() => {
        notificarSucesso();
         setSubscribedProducts(subscribedProducts.filter((product) => product.id !== productId));
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
        notificarErro();
       // setIsAdding(false);
      });
   
    setIsAllProductsVisible(false);
  
  };
  const notificarSucesso = () => toast.success("Operação concluida com sucesso!");
  const notificarErro = () => toast.error("Não foi possível concluir a operação.");
  const handleSubscribeConfirm = ({ produto, frequency, dayOfWeek, quantity }) => {
    // Handle the subscription confirmation logic here
      console.log("Subscribed:", produto, frequency, dayOfWeek, quantity);
      ApiActions.subEscreverProduto(produto.id, dayOfWeek, quantity , frequency)
      .then(() => {
        notificarSucesso();
        setSubscribedProducts([...subscribedProducts, {
      id: subscribedProducts.length + 1,
      produto: produto.produto,
      preco: produto.preco,
      frequency: frequency,
      dayOfWeek: dayOfWeek,
      foto: produto.foto,
    }]);
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
        notificarErro();
       // setIsAdding(false);
      });
   
    setIsAllProductsVisible(false);
  };

  const handleEditConfirm = ({ produto, frequency, dayOfWeek, quantity }) => {
     ApiActions.subEscreverProduto(produto.id, dayOfWeek, quantity , frequency)
      .then(() => {
        notificarSucesso();
       
      })
      .catch((error) => {
     
        notificarErro();
       // setIsAdding(false);
      });
    setIsSidebarOpen(false);
  };

  const handleBackFromAllProducts = () => {
    setIsAllProductsVisible(false);
  };

  return (
    <div>
      {isAllProductsVisible ? (
        <AllProducts onAddToCart={() => {}} onSubscribeConfirm={handleSubscribeConfirm} onBack={handleBackFromAllProducts} />
      ) : (
        <div>
          <div className="flex justify-end p-4">
            <button className="bg-customPurple text-white px-4 py-2 rounded" onClick={handleNewSubscription}>
              Nova Recorrência
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 py-8">
            {subscribedProducts.length > 0 &&  subscribedProducts.map((product) => (
              <SubscribedProductCard
                key={product.id}
                product={product}
                onEdit={handleEditSubscription}
                onDelete={handleDeleteSubscription}
              />
            ))}
          </div>
          {isSidebarOpen && (
            <Sidebar
              produto={selectedProduct}
              onClose={() => setIsSidebarOpen(false)}
              onSubscribeConfirm={handleEditConfirm}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SubscribedProducts;
